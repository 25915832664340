<script>
export default {
  props: {
    svgClass: {
      type: String,
      required: false,
      default: 'h-6 w-6 flex-shrink-0'
    }
  }
}
</script>

<template>
  <svg
    :class="[svgClass, 'duration-150 ease-in-out fill-primary']"
    width="100%"
    height="100%"
    viewBox="0 0 726 702"
    xmlns="http://www.w3.org/2000/svg"
    style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
  >
    <g id="Icon" transform="matrix(1.02154,0,0,0.988197,-906.74,0)">
      <rect x="887.623" y="0" width="710" height="710" style="fill:none;" />
      <g transform="matrix(1.45514,0,0,1.50423,660.305,-247.654)">
        <g transform="matrix(1,2.44929e-16,-2.44929e-16,1,-0.769065,157.399)">
          <path d="M364.973,197.925C374.449,188.353 387.359,182.967 400.829,182.967C414.298,182.967 427.208,188.353 436.684,197.925C483.44,245.152 566.724,329.277 630.07,393.262C644.395,407.731 648.619,429.393 640.78,448.184C632.941,466.976 614.576,479.214 594.215,479.214L207.443,479.214C187.082,479.214 168.716,466.976 160.877,448.184C153.038,429.393 157.262,407.731 171.587,393.262C234.933,329.277 318.217,245.152 364.973,197.925Z" style="fill:rgb(22,163,74);" />
        </g>
        <g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,800.888,643.852)">
          <path d="M364.973,197.925C374.449,188.353 387.359,182.967 400.829,182.967C414.298,182.967 427.208,188.353 436.684,197.925C483.44,245.152 566.724,329.277 630.07,393.262C644.395,407.731 648.619,429.393 640.78,448.184C632.941,466.976 614.576,479.214 594.215,479.214L207.443,479.214C187.082,479.214 168.716,466.976 160.877,448.184C153.038,429.393 157.262,407.731 171.587,393.262C234.933,329.277 318.217,245.152 364.973,197.925Z" style="fill:rgb(74,222,128);" />
        </g>
      </g>
    </g>
  </svg>
</template>

<style scoped>

</style>
